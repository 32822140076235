.selectedRecords{
    height: auto;
    width: 99%;
    margin-bottom: 20px;
    border: 1px solid gray;
    align-self: center;
    padding: 20px 5px;

}
.container{
    width: 100%;
    min-height: 100vh;
}
.controlersBtchMode{
    display: flex;
   padding: 10px;
   gap: 10px;
}