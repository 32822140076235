.App {
  text-align: center;
}

@import url("/src/utils/cssUtilities.css");

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
}
.close {
  font-weight: bold;
  font-size: 25px;
}
.close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.2;
}

#alertcontainer {
  max-height: 100px;
  overflow: hidden;
}

.alertstyle-info {
  border-color: #45abcd;
  background-color: #e2f0f7;
}
.alertstyle-lg {
  padding: 10px;
  font-size: 120%;
}
.alertstyle-leftborder {
  padding: 10px;
  border-left: 15px solid;
  margin-bottom: 10px;
}
