.RackStatusHeader{
    width:100%;
    height: auto;
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
}
.RackStatusBody{
    width:fit-content;
    height: auto;
    display: flex;
    padding: 10px;
    width: 100%;
    justify-content: space-evenly;
    
}
.rackStatusFooter{
    width:fit-content;
    height: auto;
    display: flex;
    padding: 10px;
    gap:10px;
    justify-content: center;
    align-items: center;
    
}