.container{
    width: 100%;
    height: 90vh;
}
.logoimage{
    width: 40%;
    margin-left: 15%;
}
.logogroup {
    width: 100%;
    margin-top: 25vh;
    position: relative;
}
.prodname {
    font-size: 3.3vw;
    color: #555;
    position: absolute;
    bottom: -1.5vw;
    left: 25.5%;
}
.searchInput{
    width: 100%;
    margin-top: 30px;
    position: relative;
    /* display: flex; */
    justify-content: center;

}
