.nav-header{
    border: 0;
    background: #205081;
    box-shadow: 0 1px 1px rgb(0 0 0 / 20%);
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.menu-section{
    display: flex;
    align-items: center;
    width: 70%;
    height: 40px;
    gap: 10px;
    padding: 0px 15px;
}
.toolbar{
    display: flex;
    align-items: center;
    padding: 0px 15px;
    height: 40px;
    gap: 10px;
}
.nav-dropdown{
    display: flex;
    align-items: center;
    height: 40px;
    gap: 5px;
    cursor: pointer;
    justify-content: center;
}
.nav-dropdown-mobile{
    display: flex;
    justify-content: space-between;
    padding: 3px 32px 3px 10px;
    color: #fff;
    font-family: 'Open Sans';
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 20px;
    text-decoration: none;
    margin-top: 2px;
}
.Recent-tool{
    display: flex;
    align-items: center;
    gap:5px;
    padding:'0px 5px';
    height: 40px;
    border-right:1px solid #fff;
    cursor: pointer;
    justify-content: center;
}
.Recent-tool:hover{
 background-color: #337ab7;
}
.nav-dropdown:hover{
    background-color: #337ab7;
}
.tools{
  display: flex;
  justify-self: flex-end;
  align-items: center;
  justify-content: flex-end;
  width: 'auto';
  gap: 10;
}
.menu-section-mobile{
    align-items: center;
    width: 100%;
    height: 43vh;
    padding: 0px 15px;
    background: #205081;
}
.Logo-mobile{
    display: flex;
    height: auto;
    align-items: flex-end;
    gap: 5px;
}
.recent-drawer-container{
    position: absolute;
    top: 0;
    color: #000;
    background-color: #fff;
    z-index: 4;
    width: 20vw;
    min-width: 300px;
    overflow: auto;
    border: 1px solid #aaa;
    margin-top: 10px;
    padding: 5px;
}
.user-dropdown{
    position: absolute;
    top: 20;
    right: 0;
    width: 10vw;
    background-color: #205081;
    padding: 10px;
    color: #fff;

}
.info-container{
    position: absolute;
    top: 10;
    right: 0;
    color: #000;
    background-color: #205081;
    z-index: 4;
    width: 15vw;
    border: 1px solid #aaa;
    padding: 10px;
    color: #fff;
    font-size: small;
    text-align: center;
}
.text-container{
    padding: 5px 0px;
    margin: 0px 0px;
}
.recent-list{
border-left: 3px solid #3D7DB4;
display: block;
margin-bottom: 4px;
padding: 4px 6px 6px;
font-size: 100%;
cursor: pointer;
}
.recent-list:hover{
    background-color: #205081;
    color:#fff
}
.recent-list-header{
    padding: 0 5px;
    background-color: #d2d2d2;
}

@media only screen and (max-width: 1024px){
    .nav-dropdown{
      display: none;
      align-items: center;
      height: 40px;
      gap: 5px;
      cursor: pointer;
      justify-content: center;
  }
  .tools{
      display: none;
      justify-self: flex-end;
      align-items: center;
      justify-content: flex-end;
      width: '30%';
    }
    .Recent-tool{
        display: none;
    }
    
}
@media only screen and (min-width: 1024px){
    .Logo-mobile{
        display: none;
    }
    .nav-dropdown-mobile{
        display: none;
    }
    .mobile-menu{
        display: none;
    }
    .menu-section-mobile{
        display: none;
    }
    
}